import axios from 'axios';

import { executeHostCommand } from '@sbrc/services';

export const restartConnectionServices = async () => {
  const restartServices = () => {
    executeHostCommand({
      command: 'services_restart',
      service: 'arm-control-bot',
    });

    executeHostCommand({
      command: 'services_restart',
      service: 'botman',
    });
  };

  /**
   * Check if health is not 'ok' to not enter a restart loop
   */
  try {
    const status = await axios.get('/api/v1/status/health');

    if (status?.data?.error) {
      restartServices();
    }
  } catch (err) {
    /**
     * Either if health is not ok, or if service is down we should restart services
     */
    restartServices();
  }
};
