import type { HostCommandData } from '@sb/feathers-types';

import { getService } from '../utils';

const SERVICE_NAME = 'hostCommand';

export async function executeHostCommand(
  data: HostCommandData,
  outputCallback?: (output: string) => void,
): Promise<void> {
  const hostCommandService = getService(SERVICE_NAME)();
  hostCommandService.timeout = 300000;

  if (outputCallback) {
    hostCommandService.on('stdout', outputCallback);
  }

  try {
    await hostCommandService.create(data);
  } finally {
    if (outputCallback) {
      hostCommandService.removeListener('stdout', outputCallback);
    }
  }
}
